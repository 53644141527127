import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import { ButtonC, TableComponent } from '../../../components';
import { D10TBody } from './D10TBody';
import { D10THead } from './D10THead';
import './styles.css'
import { useTranslation } from 'react-i18next';
export const D10Table = ({report}) => {
  const componentRef = useRef(null)
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  return (
    <TableComponent ref={componentRef} responsive={true}>
        <ButtonC variant='download' onClick={handlePrint} style={{float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
        {t('report.download_pdf_file')} <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf} />
      </ButtonC>
        <D10THead report={report}/>
        <D10TBody report={report}/>
    </TableComponent>
  )
}
