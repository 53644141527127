import {faFileCsv, faFileExcel} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import {ButtonC} from '../../components'
import {useFileDownload} from '../../hooks'
import styles from './report.module.css'

export const D10DownloadFile = ({url, query}) => {
	const {t} = useTranslation()
	const excel = useFileDownload(`${url}?`, {...query}, 'application/ms-excel')
	const csv = useFileDownload(`${url}?`, {...query}, 'text/csv')
	return (
		<div className={styles.download} id='btns'>
			<ButtonC variant="close"  onClick={async () => {
				excel.download();
			}}> <FontAwesomeIcon style={{color: 'green'}} icon={faFileExcel} />
			 {t('report.download_excel_file')}
			</ButtonC>
			{/* <ButtonC variant='download' onClick={handlePrint}>
		PDF <FontAwesomeIcon style={{color: 'red'}} icon={faFilePdf} />
	</ButtonC> */}
		</div>
	)
}
