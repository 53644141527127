import React from 'react'
import { Layout } from '../app/layout'
import './report.css'

export const Questions = () => {
  return (
    <Layout>
        <h1 className='title'>Вопросы и Ответы (ЧАВО)</h1>
        <p style={{textAlign: 'center', marginTop: '20px'}}>Часто задаваемые вопросы и ответы будут пополняться по ссылке ниже</p>
        <p style={{textAlign: 'center', marginTop: '20px'}}><a href="https://docs.google.com/document/d/117e3wHw_mzif4_Wrlr3VwGEbN__ctp7m/edit" target="blank">Документ</a></p>
    </Layout>
  )
}
