import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useEffect, useRef, useState} from 'react'
import {Table} from 'react-bootstrap'
import {ButtonC, PdfHeader} from '../../../components'
import {Link} from 'react-router-dom';
import {faAngleDown, faFileExcel, faFilePdf, faWindowClose} from '@fortawesome/free-solid-svg-icons'
import {api} from '../../../service/old-api-2';
import {useReactToPrint} from 'react-to-print';
import {useTranslation} from 'react-i18next';
import {read} from "../../../service/storage";

export const D10DistAll = () => {
    const [schools, setSchools] = useState([])
    const {t} = useTranslation()
    const getData = () => {
        api.get(`reports/d10-school-status-list/`).then(
            (response) => {
                setSchools(response)
            })
    }
    const download = () => {
        api.get('users/excel-test/', {
            responseType: 'blob',
        }).then((response) => {
            // console.log(response)
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
    }
    useEffect(() => {
        getData();
    }, [])

    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })
    let index = 1;
    const docDownloadHandler = (id, type) => {
        const access = read('access')
        const uri = `${process.env.REACT_APP_BASE_URL}/api/d10/${type}/documents/${id}`
        fetch(uri, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${access}`,
                // 'Content-Type': 'application/pdf',
            },
        })
        // .then((response) => response.json())
        // .then((data) => console.log(data))
        // .then(response = () => {
        //     if (!response.ok) {
        //         throw new Error('Сервер вернул ошибку');
        //     }
        //     return response.blob();
        // })
        .then((response) => response.blob())
        // .then((response) => {
        //     const contentDisposition = response.headers.get('Content-Disposition');
        //     console.log(response);
        //     if (contentDisposition) {
        //         const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        //         const matches = contentDisposition.match(filenameRegex);
        //         if (matches && matches.length > 1) {
        //             const filename = matches[1].replace(/['"]/g, '');
        //             // Теперь у вас есть имя файла, вы можете использовать его, например, для установки имени скачиваемого файла
        //             a.download = filename;
        //             console.log(filename);
        //         }
        //     }

        //     return response.blob();
        // })
        .then((blob) => {
            // Создайте объект URL для бинарных данных
            const url = window.URL.createObjectURL(blob);
            // Создайте ссылку для скачивания файла
            const a = document.createElement('a');
            a.href = url;
            a.download = 'd10';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);


            // Create blob link to download
            // const url = window.URL.createObjectURL(
            //     new Blob([blob]),
            // );
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute(
            //     'download',
            //     'd10.pdf',
            // );
            // Append to html link element page
            // document.body.appendChild(link);
            // Start download
            // link.click();
            // Clean up and remove the link
            // link.parentNode.removeChild(link);
            });
    }
    return (
        <div>
             <div className='d-flex justify-content-between'>
             <ButtonC variant='download' onClick={handlePrint}
                         style={{float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff'}}>
                    PDF <FontAwesomeIcon style={{color: '#fff'}} icon={faFilePdf}/>
                </ButtonC>
                <ButtonC variant="close" onClick={download}> <FontAwesomeIcon style={{color: 'green'}} icon={faFileExcel}/>Скачать Excel</ButtonC>
             </div>
            <Table striped bordered hover ref={componentRef}>
                <PdfHeader title={t('report.d10-district.title')}/>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Код школы</th>
                    <th>Название школы</th>
                    <th>Баланс школы</th>
                    <th>Имя библиотекаря</th>
                    <th>Телефон / Email</th>
                    <th>Кыргызский</th>
                    <th>Русский</th>
                    <th>Таджикский</th>
                    <th>Узбекский</th>
                    <th>Статус</th>
                    <th>Подтверждение районом</th>
                    <th>Проверить</th>
                </tr>
                </thead>
                <tbody>
                {schools && schools?.length > 0 && schools?.map((school) => {
                    return (
                        <tr key={school?.school_id}>
                            <td data-label="№">{index++}</td>
                            <td data-label="Код школы">{school?.school_id}</td>
                            <td data-label="Название школы">{school?.school_name}</td>
                            <td data-label="Баланс школы">{school?.school_balance}</td>
                            <td data-label="Имя библиотекаря">{school?.fullname}</td>
                            <td data-label="Телефон / Email">{school?.phone} / {school?.email}</td>
                            <td data-label="Кыргызский">
                                {school?.doc_kg &&
                                    <button className="btn btn-primary" onClick={() => {
                                        docDownloadHandler(school?.school_id, 'kg')
                                    }}>Скачать
                                </button>
                                }
                            </td>
                            <td data-label="Русский">
                                {school?.doc_ru &&
                                    <button className="btn btn-primary" onClick={() => {
                                        docDownloadHandler(school?.school_id, 'ru')
                                    }}>Скачать
                                </button>
                                }
                            </td>
                            <td data-label="Таджикский">
                                {school?.doc_tj &&
                                    <button className="btn btn-primary" onClick={() => {
                                        docDownloadHandler(school?.school_id, 'tj')
                                    }}>Скачать
                                </button>
                                }
                            </td>
                            <td data-label="Узбекский">
                                {school?.doc_uz &&
                                    <button className="btn btn-primary" onClick={() => {
                                        docDownloadHandler(school?.school_id, 'uz')
                                    }}>Скачать
                                </button>
                                }
                            </td>
                            <td data-label="Статус">{school?.is_d10_verified ?
                                <FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/> :
                                <FontAwesomeIcon icon={faWindowClose}
                                                 style={{color: 'orangered', fontSize: '25px'}}/>}
                            </td>
                            <td data-label="Подтверждение районом">{school?.confirmed_district ?
                                <FontAwesomeIcon icon={faAngleDown} style={{color: 'forestgreen', fontSize: '25px'}}/> :
                                <FontAwesomeIcon icon={faWindowClose}
                                                 style={{color: 'orangered', fontSize: '25px'}}/>}
                            </td>
                            <td data-label="Посмотреть">
                                {school?.is_d10_verified &&
                                    <Link to={{pathname: `/report-result/${school?.school_id}`}}>
                                        <ButtonC variant="primary">{t('report.d10-district.check')}</ButtonC>
                                    </Link>
                                }
                            </td>
                        </tr>
                    )
                })}
                <tr>

                </tr>
                </tbody>
            </Table>
        </div>
    )
}
