import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { ButtonC, PdfHeader, TableComponent } from "../../../components";
import { api } from "../../../service/new-api-2";
import { storage } from "../../../service";
import { D10DownloadFile } from "../d10-download-file";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useReactToPrint } from "react-to-print";

export const G1RentedBooks = () => {
  const school_id = storage.read("school_id");
  const { t } = useTranslation();
  const [rentalBooks, setRentalBooks] = useState([]);
  const componentRef = useRef(null);

  const getRentalBooks = () => {
    api.get(school_id ? `reports/rental-books/?school_id=${school_id}` : 'reports/rental-books/')
      .then((response) => {
        setRentalBooks(response);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getRentalBooks();
  }, []);

  const calculateTotals = (books) => {
    const totals = {
      total: 0,
      student_count: 0,
      student_count_0_discount: 0,
      student_count_50_discount: 0,
      student_count_100_discount: 0,
      given: 0,
      given_0_discount: 0,
      given_50_discount: 0,
      given_100_discount: 0,
      total_amount: 0,
      total_amount_0_discount: 0,
      total_amount_50_discount: 0,
      total_amount_100_discount: 0,
      remaining: 0,
      not_returned_amount: 0
    };
    books.forEach((book) => {
      totals.total += book.total;
      totals.student_count += book.student_count;
      totals.student_count_0_discount += book.student_count - book.student_count_50_discount - book.student_count_100_discount;
      totals.student_count_50_discount += book.student_count_50_discount;
      totals.student_count_100_discount += book.student_count_100_discount;
      totals.given += book.given;
      totals.given_0_discount += book.given - book.given_to_50_discount - book.given_to_100_discount;
      totals.given_50_discount += book.given_to_50_discount;
      totals.given_100_discount += book.given_to_100_discount;
      totals.total_amount += book.total_amount;
      totals.total_amount_0_discount += book.total_amount - book.total_amount_50_discount - book.total_amount_100_discount;
      totals.total_amount_50_discount += book.total_amount_50_discount;
      totals.total_amount_100_discount += book.total_amount_100_discount;
      totals.remaining += book.remaining;
      totals.not_returned_amount += book.not_returned_amount;
    });

    return totals;
  };

  const totals = calculateTotals(rentalBooks);

  return (
    <div>
      <TableComponent ref={componentRef}>
        <ButtonC
          variant="download"
          onClick={handlePrint}
          style={{
            float: "left",
            marginBottom: "15px",
            backgroundColor: "red",
            color: "#fff",
          }}
        >
          PDF <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
        </ButtonC>
        <PdfHeader title={t("report.g1.title")} />
        <thead>
          <tr>
            <td>№</td>
            <td>{t("report.g1.book-name")}</td>
            <td>{t("report.g1.book-author")}</td>
            <td>{t("report.g1.book_grades")}</td>
            <td>{t("report.g1.book-class-language")}</td>
            <td>{t("report.g1.total-books")}</td>
            <td>{t("report.g1.students-count")}</td>
            <td>{t("report.g1.students-count-0%-discount")}</td>
            <td>{t("report.g1.students-count-50%-discount")}</td>
            <td>{t("report.g1.students-count-100%-discount")}</td>
            <td>{t("report.g1.rented-books")}</td>
            <td>{t("report.g1.rented-books-0%-discount")}</td>
            <td>{t("report.g1.rented-books-50%-discount")}</td>
            <td>{t("report.g1.rented-books-100%-discount")}</td>
            <td>{t("report.g1.total-amount")}</td>
            <td>{t("report.g1.total-amount-0%-discount")}</td>
            <td>{t("report.g1.total-amount-50%-discount")}</td>
            <td>{t("report.g1.total-amount-100%-discount")}</td>
            <td>{t("report.g1.remaining-books")}</td>
            <td>{t("report.g1.remaining-books-amount")}</td>
          </tr>
        </thead>
        <tbody>
          {rentalBooks && rentalBooks.length > 0 &&
            rentalBooks.map((books, index) => (
              <tr key={books.id}>
                <td data-label="№">{index + 1}</td>
                <td data-label="Наименование учебника">{books.book_name}</td>
                <td data-label="Автор">{books.book_author}</td>
                <td data-label="Класс">{books.book_grades.join(",")}</td>
                <td data-label="Язык обучения">{books.book_language === 'kyrgyz' ? 'кыргызкий' : books.book_language === 'russian' ? 'русский' : books.book_language === 'uzbek' ? 'узбекский' : 'таджикский'}</td>
                <td data-label="Количество книг">{books.total}</td>
                <td data-label="Количество учащихся">{books.student_count}</td>
                <td data-label="Количество учащихся без льгот">{books.student_count - books.student_count_50_discount - books.student_count_100_discount}</td>
                <td data-label="Количество учащихся c 50% льготой">{books.student_count_50_discount}</td>
                <td data-label="Количество учащихся c 100% льготой">{books.student_count_100_discount}</td>
                <td data-label="Выдано всего">{books.given}</td>
                <td data-label="Выдано без льгот">{books.given - books.given_to_50_discount - books.given_to_100_discount}</td>
                <td data-label="Выдано c 50% льготой">{books.given_to_50_discount}</td>
                <td data-label="Выдано cо 100% льготой">{books.given_to_100_discount}</td>
                <td data-label="Сумма аренды всего">{books.total_amount}</td>
                <td data-label="Сумма аренды без льгот">{books.total_amount - books.total_amount_50_discount - books.total_amount_100_discount}</td>
                <td data-label="Сумма аренды с 50% льготой">{books.total_amount_50_discount}</td>
                <td data-label="Сумма аренды с 100% льготой">{books.total_amount_100_discount}</td>
                <td data-label="Осталось">{books.remaining}</td>
                <td data-label="Осталось на сумму">{books.not_returned_amount}</td>
              </tr>
            ))
          }
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5">{t("report.g1.total")}</td>
            <td>{totals.total}</td>
            <td>{totals.student_count}</td>
            <td>{totals.student_count_0_discount}</td>
            <td>{totals.student_count_50_discount}</td>
            <td>{totals.student_count_100_discount}</td>
            <td>{totals.given}</td>
            <td>{totals.given_0_discount}</td>
            <td>{totals.given_50_discount}</td>
            <td>{totals.given_100_discount}</td>
            <td>{totals.total_amount}</td>
            <td>{totals.total_amount_0_discount}</td>
            <td>{totals.total_amount_50_discount}</td>
            <td>{totals.total_amount_100_discount}</td>
            <td>{totals.remaining}</td>
            <td>{totals.not_returned_amount}</td>
          </tr>
        </tfoot>
      </TableComponent>
    </div>
  );
};
