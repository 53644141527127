import {useState, useEffect } from 'react'
import {Col, Row} from 'react-bootstrap'
import {api}  from '../../service/old-api-2'
import { storage } from '../../service'
import {VideoItem} from './video-item'

export const Videos = () => {
	const [videos, setVideos] = useState()
	const getVideos = () => {
		api.get('videos/').then((response) => {
			setVideos(response.reverse())
		})
	}

	useEffect(() => {
		getVideos()
	}, [])
	return (
		<Row xs={1} md={2}>
			{videos?.map((vid) => (
				<Col key={vid.url}>
					<VideoItem title={vid?.name} url={vid?.url} />
				</Col>
			))}
		</Row>
	)
}
const data = [
	{
		title: 'НурЧолпон - Китепканачылар гимни',
		src: 'wHN1IswjqOo'
	},
	{
		title: 'Видеоруководство пользователя автоматизированной информационной системы “Жаңы китеп”',
		src: 'ik8axZJy7W0'
	},
	{
		title: '"Жаңы китеп" автоматташтырылган маалымат тутумунун колдонуучунун видео көрсөтмөсү',
		src: '6CF6WaOJjt8'
	},
	{
		title: 'Жаны китеп. KY (китепкана)',
		src: 'ZBiI9C0rlHw'
	},
	{
		title: 'Жаңы китеп - #1китеп1окуучу',
		src: '4dX95bpR82A'
	},
	{
		title: 'АИС "Жаны китеп" (библиотекарь)',
		src: 'FehqRO_l8T0'
	},
	{
		title: 'Экономия родительских средств',
		src: 'z4RUa4iLRvI'
	},
	{
		title: 'Что делать при утере учебника',
		src: 'FryDJfF6Bn0'
	},
	{
		title: 'Сколько стоит аренда учебников',
		src: 'VTrn47LHr2U'
	},
	{
		title: 'Путь учебника',
		src: '6f3d6DX9OoA'
	},
	{
		title: 'Переход из одной школы в другую',
		src: 'FyHGyoOXmTU'
	},
	{
		title: 'Льготы для разных категорий учеников',
		src: 'q1A3BZruddU'
	},
	{
		title: 'Контрафактные учебники',
		src: 'wnFXBwpOFm4'
	},
	{
		title: 'Возврат средств',
		src: '1B1EG29kkq4'
	},
	{
		title: 'Жаны китептин жолу',
		src: '_emc47OPTkM'
	},
	{
		title: 'Башка мектепке которулуу',
		src: 'pOr9A7TdWY4'
	},
	{
		title: 'Үй бүлөлүк бюджетти үнөмдөө',
		src: 'QZWy_9ZTm9o'
	},
	{
		title: 'Каражатты кайтаруу',
		src: '_H59QfdhcCY'
	},
	{
		title: 'Китепти жоготуп алганда',
		src: '8r13T1Ug4NA'
	},
	{
		title: 'Жасалма окуу китептери',
		src: 'NRdNFo2NQSI'
	},
	{
		title: 'Ижарага алуу канча турат',
		src: 'ffrLdxNWjv4'
	},
	{
		title: 'Ижара акысынан жеңилдиктерди ким алат',
		src: 'zj7j4l2dtqc'
	},
	{
		title: 'Окуу китебиндеги QR code',
		src: 'y-ViFxCLYPM'
	},
	{
		title: 'Попурри в честь последнего звонка 2020 Кыргызстан',
		src: 'BOTqySDYZwU'
	},
	{
		title: '«Жаңы китеп» кино тасмасы',
		src: 'p_oCusyu_3A'
	},
]