import React, { useEffect, useRef, useState } from 'react'
import { Col } from 'react-bootstrap'
import { ButtonC, PdfHeader, TableComponent } from '../../../components'
import { api } from '../../../service/new-api-2'
import { storage } from '../../../service'
import { D10DownloadFile } from '../d10-download-file'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { useReactToPrint } from 'react-to-print'

export const G1RentedBooksDist = () => {
    const district_id = storage.read('district_id')
    const { t } = useTranslation()
    const [rentalBooks, setRentalBooks] = useState([])

    const getRentalBooks = () => {
        api.get(`reports/rental-books/?district_id=${district_id}`).then((response) => {
            setRentalBooks(response)
        })
    }

    useEffect(() => {
        getRentalBooks()
    }, [])

    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const calculateTotals = (books) => {
        const totals = {
            total: 0,
            given: 0,
            remaining: 0
        }

        books.forEach((book) => {
            totals.total += book.total
            totals.given += book.given
            totals.remaining += book.remaining
        })

        return totals
    }
    const totals = calculateTotals(rentalBooks)
    let index = 1

    return (
        <div>
            <>
                <TableComponent ref={componentRef}>
                    <ButtonC
                        variant='download'
                        onClick={handlePrint}
                        style={{ float: 'left', marginBottom: '15px', backgroundColor: 'red', color: '#fff' }}
                    >
                        PDF <FontAwesomeIcon style={{ color: '#fff' }} icon={faFilePdf} />
                    </ButtonC>
                    <PdfHeader title={t('report.g1.title')} />
                    <thead>
                        <tr>
                            <td>№</td>
                            <td>{t('report.g1.book-name')}</td>
                            <td>{t('report.g1.book-author')}</td>
                            <td>{t('report.g1.book-class-language')}</td>
                            <td>{t('report.g1.book_grades')}</td>
                            <td>{t('report.g1.total-books')}</td>
                            <td>{t('report.g1.rented-books')}</td>
                            <td>{t('report.g1.remaining-books')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {rentalBooks && rentalBooks.length > 0 && rentalBooks.map((books) => (
                            <tr key={books.id}>
                                <td data-label="№">{index++}</td>
                                <td data-label="Наименование учебника">{books.book_name}</td>
                                <td data-label="Автор">{books.book_author}</td>
                                <td data-label="Язык обучения">{books.book_language === 'kyrgyz' ? 'кыргызкий' : (books.book_language === 'russian' ? 'русский' : (books.book_language === 'uzbek' ? 'узбекский' : 'таджикский'))}</td>
                                <td data-label="Классы">{(books.book_grades).join(',')}</td>
                                <td data-label="Количество книг">{books.total}</td>
                                <td data-label="Выдано">{books.given}</td>
                                <td data-label="Осталось">{books.remaining}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="5">{t('report.g1.total')}</td>
                            <td>{totals.total}</td>
                            <td>{totals.given}</td>
                            <td>{totals.remaining}</td>
                        </tr>
                    </tfoot>
                </TableComponent>
            </>
        </div>
    )
}
