import { useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next'
import {TableComponent} from '../../components'
import { storage } from '../../service'
import { api } from '../../service/old-api-2'

import {PaymentsFilter} from './payments-filter'

export const Payment = () => {
	const {t} = useTranslation()
	const [paymentItem, setPaymentItem] = useState()
    const getPaymentData = () => {
        api.get(`payments/?school_id=${storage.read('school_id')}`).then((response) => {
            setPaymentItem(response)
        })
    }
	let index = 1;
    useEffect(() =>{
        getPaymentData()
    }, [])
	return (
		<div>
			<PaymentsFilter setPaymentItem={setPaymentItem} />
			<TableComponent>
				<thead>
					<tr>
						<th>#</th>
						<th>{t('payment.date')}</th>
						<th>{t('payment.number_operation')}</th>
						<th>Сервис</th>
						<th>{t('payment.school')}</th>
						<th>Класс</th>
						<th>Период обучения</th>
						<th>{t('payment.summ')}</th>
						<th>{t('payment.success')}</th>
					</tr>
				</thead>
				<tbody>
				{paymentItem?.map((paymentItem) => {
					return (
						<>
			<tr >
            <td data-label="№">{index++}</td>
            <td data-label="Дата">{paymentItem?.payment_date}</td>
            <td data-label="Номер операции">{paymentItem?.transaction_id}</td>
            <td data-label="Сервис">{paymentItem?.service_provider}</td>
            <td data-label="Школа">{paymentItem?.school_id}</td>
            <td data-label="Класс">{paymentItem?.klass}</td>
            <td data-label="Период обучения">{paymentItem?.study_period}</td>
            <td data-label="Сумма">{paymentItem?.payment_amount}</td>
            <td data-label="Кто оплатил">{paymentItem?.purpose_of_payment}</td>
        </tr>
		</>
					)
    })}
				</tbody>
			</TableComponent>
		</div>
	)
}